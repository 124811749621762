import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Layout, Hero, About, Jobs, Featured, Projects, Contact} from '@components';
import Certficiation from "../components/sections/certification";
import { inject } from "@vercel/analytics";

const StyledMainContainer = styled.main`
    counter-reset: section;
`;

inject();

const IndexPage = ({location}) => (
    <Layout location={location}>
        <StyledMainContainer className="fillHeight">
            <Hero/>
            <About/>
            <Jobs/>
            <Certficiation/>
            <Featured/>
            {/*<Projects />*/}
            <Contact/>
        </StyledMainContainer>
    </Layout>
);

IndexPage.propTypes = {
    location: PropTypes.object.isRequired,
};

export default IndexPage;
